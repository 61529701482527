var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pendingConfirm
    ? _c(
        "div",
        { staticClass: "d-flex align-items-center flex-gap-2" },
        [
          _c("span", { staticClass: "text-danger" }, [
            _vm._v("Do you confirm?"),
          ]),
          _c(
            "CLink",
            { on: { click: _vm.confirmStep } },
            [_c("CIcon", { attrs: { name: "cil-check-alt" } })],
            1
          ),
          _c(
            "CLink",
            { on: { click: _vm.cancelConfirm } },
            [_c("CIcon", { attrs: { name: "cil-x" } })],
            1
          ),
        ],
        1
      )
    : _c(
        "CLink",
        { on: { click: _vm.startConfirmation } },
        [_c("CIcon", { attrs: { name: "cil-arrow-thick-right" } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }