var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CDataTable", {
    staticClass: "order-messages",
    attrs: {
      hover: "",
      items: _vm.messages,
      fields: _vm.fields,
      "clickable-rows": "",
    },
    on: { "row-clicked": _vm.rowClicked },
    scopedSlots: _vm._u([
      {
        key: "channel",
        fn: function ({ item }) {
          return [
            _c("td", [
              _c("img", {
                attrs: { src: `/img/icons/${item.channel}.svg`, height: "24" },
              }),
            ]),
          ]
        },
      },
      {
        key: "created_at",
        fn: function ({ item }) {
          return [
            _c("td", [
              _vm._v(
                _vm._s(
                  _vm.moment(item.created_at).format("HH:mm:ss, DD MMMM YYYY")
                )
              ),
            ]),
          ]
        },
      },
      {
        key: "show_details",
        fn: function ({ item, index }) {
          return [
            _c(
              "td",
              { staticClass: "text-right" },
              [
                _c(
                  "CButton",
                  {
                    attrs: { size: "sm", color: "link" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleDetails(item, index)
                      },
                    },
                  },
                  [
                    Boolean(item._toggled)
                      ? _c("CIcon", {
                          attrs: { name: "cil-chevron-circle-up-alt" },
                        })
                      : _c("CIcon", {
                          attrs: { name: "cil-chevron-circle-down-alt" },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "details",
        fn: function ({ item, index }) {
          return [
            _c(
              "CCollapse",
              {
                attrs: {
                  show: Boolean(item._toggled),
                  duration: _vm.collapseDuration,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column flex-gap-2 p-2" },
                  [
                    _c("div", [
                      _c("strong", [_vm._v("Receiver:")]),
                      _vm._v(" " + _vm._s(item.receiver)),
                    ]),
                    _c("div", [
                      _c("strong", [_vm._v("App ID:")]),
                      _vm._v(" " + _vm._s(item.app_id)),
                    ]),
                    item.content
                      ? _c(
                          "div",
                          [
                            _c("strong", [_vm._v("Content")]),
                            _c("json-viewer", {
                              attrs: { value: item.content, "expand-depth": 2 },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.response
                      ? _c(
                          "div",
                          [
                            _c("strong", [_vm._v("Response")]),
                            _c("json-viewer", {
                              attrs: {
                                value: item.response,
                                "expand-depth": 2,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }